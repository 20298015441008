import * as EpicAutoLinkTemplate from 'app/client/core/views/templates/epicAutoLink.html';
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="change-icon change-icon-';if (d.created) {str += 'green';} else {str += 'gray';}str += '">'+'  <span class="fa fa-';if (d.created) {str += 'plus';} else {str += 'trash';}str += '"></span>'+'</div>'+''+'<div class="value">'+'  <strong>';str += (d.name);str += '</strong>'+'  ';if (d.created) {str += '    ';if (d.isMention) {str += '      mentioned you while creating'+'    ';} else if (d.isGroupMention) {str += '      mentioned your group while creating'+'    ';} else {str += '      created'+'    ';}str += '  ';} else {str += '    deleted'+'  ';}str += '  the Epic ';str += EpicAutoLinkTemplate.render( d.epic);str += '</div>';if (d.created && (d.isMention || d.isGroupMention)) {str += '  <div class="comment markdown-formatted">'+'    ';str += (d.description);str += '  </div>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
