import * as GroupAutoLinkTemplate from 'app/client/core/views/templates/groupAutoLink.html';
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="change-icon change-icon-orange">'+'    <span class="fa fa-group"></span>'+'</div>'+''+'<div class="value">'+'    <strong>';str += (d.name);str += '</strong>'+'    ';if (d.added) {str += 'added';} else if (d.removed) {str += 'removed';}str += ' ';if (d.name === 'You') {str += ' yourself ';} else {str += ' you ';}str += ' ';if (d.added) {str += 'to';} else {str += 'from';}str += ' ';str += GroupAutoLinkTemplate.render( d.group);str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
