
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="value">'+'  <strong>';str += (d.name);str += '</strong>'+'  reacted with ';str += (d.emoji);str += ' to'+'  ';if (d.isAuthor) {str += 'your';} else {str += 'a';}str += '  comment from'+'  ';str += (d.story);str += '</div>';if (d.comment) {str += '<div class="comment markdown-formatted">'+'  ';str += (d.comment);str += '</div>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
