import * as EpicAutoLinkTemplate from 'app/client/core/views/templates/epicAutoLink.html';
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';if (!d.added) {str += '  <div class="change-icon change-icon-gray">'+'    <span class="fa fa-trash"></span>'+'  </div>';}str += '<div class="value">'+'  <strong>';str += (d.name);str += '</strong>'+'  ';if (d.added) {str += '    ';if (d.isMention) {str += '      mentioned you in a comment on'+'    ';} else if (d.isGroupMention) {str += '      mentioned your group in'+'    ';} else {str += '      commented on'+'    ';}str += '  ';} else {str += '    deleted a comment from'+'  ';}str += '  the Epic ';str += EpicAutoLinkTemplate.render( d.epic);str += '</div>'+''+'<div class="comment ';if (!d.added) {str += 'deleted-comment ';}str += 'markdown-formatted">'+'  ';str += '  ';if (d.added) {str += '    ';str += (d.comment);str += '  ';}str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
