import CommentController from 'app/client/core/js/controllers/comment.js';
import User from 'app/client/core/js/modules/user.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Comment'], CommentController],
  [['User'], User],
  [['Controller', 'Comment'], CommentController],
  [['User'], User]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _CfS = Caveman.forceStr; var str = '';var hasReactions = d.reactions && d.reactions.length > 0;str += '';_ds.push(d); var _d0 = d.reactions; for (var _i0 = 0, _len0 = (d.reactions || []).length; _i0 < _len0; _i0++) { _i = _i0; _len = _len0;d = _d0[_i0];str += '  ';if (d.permission_ids && d.permission_ids.length) {str += '    <button class="reaction ';if (d.byCurrentUser) {str += 'highlighted';}str += '"'+'            data-controller="Comment"'+'            data-on-click="toggleReaction"'+'            data-shortcode="';str += _CfS( d.emoji );str += '"'+'            data-tooltip'+'            data-tooltip-fn="App.Controller.Comment.listUsersWhoReacted"'+'    >'+'      ';str += (d.formattedEmoji);str += ' ';str += _CfS( d.permission_ids.length );str += '    </button>'+'  ';}str += '';} d = _ds.pop();str += '';if (hasReactions) {str += '  <button class="reaction add-reaction" data-controller="Comment" data-on-click="addReaction" data-tooltip="Add Reaction">'+'    <span class="fa fa-smile-o"></span>'+'    <span class="fa fa-plus"></span>'+'  </button>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
