
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';if (d.type === 'title') {str += '  <div class="shimmer shimmer-title"></div>';} else if (d.type === 'story') {str += '  <div class="shimmer shimmer-story"></div>';} else {str += '  <div class="shimmer shimmer-text"></div>';}return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
