import * as PartialStoryThreadedCommentsV2Template from 'app/client/core/views/templates/partialStoryThreadedCommentsV2.html';import CommentController from 'app/client/core/js/controllers/comment.js';
import CommentModel from 'app/client/core/js/models/comment.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Comment'], CommentController],
  [['Model', 'Comment'], CommentModel],
  [['Controller', 'Comment'], CommentController],
  [['Model', 'Comment'], CommentModel]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';if (!App.Model.Comment.commentsAreAscending()) {str += '    ';str += (App.Controller.Comment.renderMarkdownEditor('new-before', d.entity.id));str += '';}str += '<div class=\'threaded-comments-container\'>'+'    ';_ds.push(d); var _d0 = App.Model.Comment.parseThreadedComments(d.comments); for (var _i0 = 0, _len0 = (App.Model.Comment.parseThreadedComments(d.comments) || []).length; _i0 < _len0; _i0++) { _i = _i0; _len = _len0;var comment = _d0[_i0];str += '        ';str += PartialStoryThreadedCommentsV2Template.render( {comment, entity: d.entity});str += '    ';} d = _ds.pop();str += '</div>';if (App.Model.Comment.commentsAreAscending()) {str += '    ';str += (App.Controller.Comment.renderMarkdownEditor('new-after', d.entity.id));str += '';}return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
