import * as IterationCardTemplate from 'app/client/iterations/views/templates/iterationCard.html';
import * as IterationCardShimmerTemplate from 'app/client/iterations/views/templates/iterationCardShimmer.html';import Iteration from 'app/client/core/js/modules/iteration.js';
import IterationsPageController from 'app/client/iterations/js/controllers/iterationsPage.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Iteration'], Iteration],
  [['Controller', 'IterationsPage'], IterationsPageController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';if (d.iterations.length > 0) {str += '<div class="iterations-column-view clearfix">'+'  <div class="iterations-columns-outer">'+'    <div class="iterations-columns-inner">'+'      ';_ds.push(d); var _d1 = d.iterations; for (var _i1 = 0, _len1 = (d.iterations || []).length; _i1 < _len1; _i1++) { _i = _i1; _len = _len1;var iteration = _d1[_i1];str += '        ';str += IterationCardTemplate.render( { iteration: iteration, usesAssociatedTeams: d.usesAssociatedTeams });str += '      ';} d = _ds.pop();str += '      ';if (d.iterations.length < d.length) {str += '        ';str += IterationCardShimmerTemplate.render( d);str += '      ';}str += '    </div>'+'  </div>'+'</div>'+'<div data-component-property-key="iterationsContextMenu" data-component-key="iterationsContextMenuRoot"></div>';} else {str += '  ';str += (App.Controller.IterationsPage.renderNoMatchingIterations());str += '';}return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
