import Iteration from 'app/client/core/js/modules/iteration.js';
import IterationsPageController from 'app/client/iterations/js/controllers/iterationsPage.js';
import IterationsPageFiltersController from 'app/client/iterations/js/controllers/iterationsPageFilters.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Iteration'], Iteration],
  [['Controller', 'IterationsPage'], IterationsPageController],
  [['Controller', 'IterationsPageFilters'], IterationsPageFiltersController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="page-filters flex-filters" data-controller="IterationsPageFilters">'+'  <div class="filter-group">'+'    <div class="filter">'+'      <div class="filter-label"><span>Filter</span></div>'+'      <div class="search-query-parent ';if (d.query) {str += 'has-query';}str += '">'+'        <input type="text" id="page-search-query" data-on-keyup="onSearchQueryKeyUp"'+'          placeholder="Filter Iterations by name" value="';str += _CfS( d.query );str += '" />'+'        <button class="clear-query-button" data-on-click="clearQuery"><span class="fa fa-times"></span></button>'+'        <span class="fa fa-search"></span>'+'      </div>'+'    </div>'+'    <div class="filter react-filter">'+'      <div class="filter-label"><span>Iteration States</span></div>'+'      <div id="iterations-status-filter" data-component-property-key="iterationStatusFilter"'+'        data-component-key="iteration-status-filter"></div>'+'    </div>'+'    ';if (d.shouldRenderTeamsFilter) {str += '    <div class="filter react-filter">'+'      <div class="filter-label"><span>Teams</span></div>'+'      <div data-component-key="groupFilter" data-component-property-key="groupFilter"></div>'+'    </div>'+'    ';}str += '    ';if (d.shouldRenderAssociatedTeamsFilter) {str += '    <div class="filter react-filter">'+'      <div data-component-key="show-associated-teams-checkbox"'+'        data-component-property-key="showAssociatedTeamsCheckbox"></div>'+'    </div>'+'    ';}str += '    <div id="iteration-automations-cta" data-component-property-key="automationsCTA"'+'      data-component-key="iteration-automations-cta"></div>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
