import * as ShimmerTemplate from 'app/client/core/views/templates/shimmer.html';
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="iteration-card iteration-card-shimmer">'+'  <div class="iteration-card-header" style="margin-bottom: 20px">'+'    ';str += ShimmerTemplate.render( { type: 'title' });str += '    ';str += ShimmerTemplate.render( d);str += '  </div>'+'  <div class="iteration-card-content">'+'    ';str += ShimmerTemplate.render( { type: 'story' });str += '    ';str += ShimmerTemplate.render( { type: 'story' });str += '    ';str += ShimmerTemplate.render( { type: 'story' });str += '  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
