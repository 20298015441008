import * as AttachmentTemplate from 'app/client/core/views/templates/attachment.html';import CommentController from 'app/client/core/js/controllers/comment.js';
import CommentModel from 'app/client/core/js/models/comment.js';
import LinkedFileModel from 'app/client/core/js/models/linkedFile.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Comment'], CommentController],
  [['Model', 'Comment'], CommentModel],
  [['Model', 'LinkedFile'], LinkedFileModel],
  [['Controller', 'Comment'], CommentController],
  [['Model', 'Comment'], CommentModel],
  [['Model', 'LinkedFile'], LinkedFileModel]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _Cw = Caveman.each;var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';_Cw([d.comment], function (comment, _key) {str += '    ';if (_.isNumber(comment.size) || App.Model.LinkedFile.isLinkedType(comment)) {str += '        <div data-controller="Comment" data-id="';str += _CfS(comment.id);str += '" class="comment-wrapper';if (App.Model.Comment.isCommentDeleted(comment)) {str += ' deleted';}if (App.Model.Comment.wasEdited(comment)) {str += ' was-edited';}str += '" data-updated-at="';str += _CfS(comment.updated_at);str += '" style="margin-bottom: 10px;">'+'            ';str += AttachmentTemplate.render( comment);str += '        </div>'+'    ';} else if (comment.type === 'comment') {str += '        ';str += (App.Controller.Comment.renderStoryCommentThread(d.entity.id, comment));str += '    ';}str += '';});str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
